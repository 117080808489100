import Compartir from 'components/3cat/Compartir';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import __isEmpty from 'lodash/isEmpty';
import styles from './destacat.module.scss';
const Share = (props) => {
    const { titol, tipus, tipologia, linkId, route } = props;
    let _tipus = tipus.toUpperCase();
    return (
        <>
            {tipologia !== 'DTY_DESTACAT' && !__isEmpty(tipologia) && !__isEmpty(linkId.toString()) && (
                <AfegeixALaLlista className={styles.afegeix} tooltipPosition='right' id={linkId} tipologia={tipologia} />
            )}
            {_tipus !== 'SUPERDESTACATTEMATIC' && _tipus !== 'SUPERDESTACAT' && (
                <Compartir titol={titol} className={styles.sharing} route={route} />
            )}
        </>
    );
};

export default Share;
