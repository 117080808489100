import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Boto from 'components/ui/Boto';
import LogoDirecte from 'components/3cat/ui/LogoDirecte';
import __isEmpty from 'lodash/isEmpty';
import styles from './destacat.module.scss';

export default function Destacat(props) {
    const {
        supertitol,
        avantitol,
        titol,
        imatges,
        entradeta,
        className,
        tipus,
        link,
        linkTipus,
        tipologia,
        tipologiaRelacionada,
        slugPrograma,
        linkId,
        canals,
        tema
    } = props;
    let { textBoto, iconaBoto } = props;

    const isDirecteRadio = ['cr', 'ci', 'cm', 'ic', 'icat', 'ocr1'].includes(linkId);

    textBoto = !__isEmpty(textBoto) ? textBoto : isDirecteRadio ? 'ESCOLTA-HO EN DIRECTE' : 'MIRA-HO EN DIRECTE';

    iconaBoto = !__isEmpty(iconaBoto) ? iconaBoto : 'directes';

    const stylesDestacat = clsx({
        [styles.destacat]: true,
        [styles.bigDestacat]: !__isEmpty(supertitol),
        [styles[tipus]]: !__isEmpty(tipus),
        [styles[className]]: !__isEmpty(className)
    });

    return (
        <div className={stylesDestacat} data-testid={'superdestacat-directe'}>
            {!__isEmpty(supertitol) && <h1 className={styles.supertitol}>{supertitol}</h1>}

            <section className={styles.cos}>
                <div className={styles.descripcio}>
                    <LogoDirecte logo={linkId} canal={linkId} className={styles.logodirecte} dataTestId='destacat-logocanal' />
                    {!__isEmpty(avantitol) && <span className={styles.avantitol}>{avantitol}</span>}
                    <Enllac href={link}>
                        <h2 className={styles.titol_directe}>
                            <span>{titol}</span>
                        </h2>
                    </Enllac>
                    <p>{entradeta}</p>

                    <Boto
                        dataTestId={'boto-destacat'}
                        text={textBoto}
                        icona={iconaBoto}
                        className={styles.boto}
                        iconaInline={true}
                        isIconaDreta={true}
                        tag='a'
                        canals={canals}
                        tipologia={tipologia}
                        tipologiaRelacionada={tipologiaRelacionada}
                        slugPrograma={slugPrograma}
                        linkId={linkId}
                        linkSlug={titol}
                        type={linkTipus}
                        link={link}
                        estil='ple'
                        tema={tema}
                        fons='fosc'
                    />
                </div>

                <div className={styles.imatgeWrapper}>
                    <Imatge
                        imatges={imatges}
                        className={styles.imatge}
                        tipus='web_destacat'
                        alt={titol}
                        aria-hidden='true'
                        layout='fill'
                        objectFit='cover'
                        priority={true}
                        crop='1903'
                    />
                </div>
            </section>
        </div>
    );
}

Destacat.defaultProps = {
    titol: '',
    entradeta: '',
    tipus: ''
};
