import Destacat from './Destacat';
import DestacatDirecte from './Destacat.directe';
import DestacatHoritzontal from './Destacat.horitzontal';
import DestacatVideoWebview from './Destacat.videoWebview';

export default function Component(props) {
    const { tipus } = props;

    switch (tipus) {
        case 'horitzontal':
            return <DestacatHoritzontal {...props} />;
        case 'DIRECTES':
            return <DestacatDirecte {...props} />;
        case 'itemVideoWebview':
            return <DestacatVideoWebview {...props} />;
        default:
            return <Destacat {...props} />;
    }
}
