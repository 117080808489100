import { getData } from '@portal-internet/bff';
import { replaceURLDomain } from '@portal-internet/core';
import __get from 'lodash/get';
import { useState } from 'react';
import { useQuery } from 'react-query';

//Revisar
export const useData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [paginacio, setPaginacio] = useState({});

    const getDades = async (url) => {
        if (url) {
            url = replaceURLDomain(url);
            setIsLoading(true);
            const rawData = await getData({ queryKey: ['tira', { url }] });
            setPaginacio(__get(rawData, 'resposta.paginacio'));
            setData(__get(rawData, 'resposta.items.item', __get(rawData, 'canal', __get(rawData, 'resposta', []))));
            setIsLoading(false);
        }
    };

    const getApiData = (field, urlDades) =>
        useQuery([field, { url: urlDades }], getData, {
            enabled: false,
            staleTime: Infinity,
            refetchOnMount: false
        });

    return {
        isLoading,
        data,
        paginacio,
        getDades,
        getApiData
    };
};
