import { removeHtmlTags } from '@portal-internet/core';
import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import __isEmpty from 'lodash/isEmpty';
import styles from './destacat.module.scss';

export default function Destacat(props) {
    const {
        supertitol,
        avantitol,
        titol,
        titolPromo,
        imatges,
        entradeta,
        entradetaPromo,
        className,
        tipus,
        link,
        linkTipus,
        tipologia,
        tipologiaRelacionada,
        slugPrograma,
        linkId,
        canals
    } = props;

    const stylesDestacat = clsx({
        [styles.destacat]: true,
        [styles.bigDestacat]: !__isEmpty(supertitol),
        [styles[tipus]]: !__isEmpty(tipus),
        [styles[className]]: !__isEmpty(className)
    });

    const route = {
        href: link,
        slug: titol,
        slugPrograma: slugPrograma,
        id: linkId,
        type: linkTipus,
        tipologia: tipologia,
        tipologiaRelacionada: tipologiaRelacionada,
        canals: canals
    };

    if (!__isEmpty(imatges) && !__isEmpty(titol)) {
        return (
            <div className={stylesDestacat}>
                {!__isEmpty(supertitol) && <h1 className={styles.supertitol}>{supertitol}</h1>}

                <Enllac route={route} className={styles.tipus}>
                    <div className={styles.h_img}>
                        <Imatge
                            imatges={imatges}
                            className={styles.h_img__imatge}
                            tipus='web_destacat_horitzontal'
                            alt={titol}
                            aria-hidden='true'
                            width='670'
                            height='378'
                            priority={true}
                            crop='621x350'
                        />
                    </div>

                    <div className={styles.h_text}>
                        <div className={styles.h_avantitol}>{avantitol}</div>
                        <h2 className={styles.h_titol}>
                            <span>{titolPromo || titol}</span>
                        </h2>
                        {entradetaPromo && <p className={styles.h_entradeta}>{removeHtmlTags(entradetaPromo)}</p>}
                        {!entradetaPromo && entradeta && <p className={styles.h_entradeta}>{removeHtmlTags(entradeta)}</p>}
                    </div>
                </Enllac>
            </div>
        );
    } else {
        return false;
    }
}

Destacat.defaultProps = {
    titol: '',
    entradeta: '',
    tipus: ''
};
