import {
    getItems,
    getPercentatgeVist,
    getResumeText,
    isClientDataUrlReadyToUse,
    normalizeApiItems,
    replaceClientDataUrlVariables
} from '@portal-internet/core';
import { useData } from 'hooks/useData';
import { useUser } from 'hooks/useUser';
import { useState, useEffect } from 'react';

export const useEstasVeient = (props) => {
    const url_dades =
        'https://api.3cat.cat/usuaris/recomanacions/videos?_format=json&usuari_id={{USUARI_ID}}&uuid={{UUID}}&dispositiu={{DEVICE}}&items_pagina=1&pagina=1&version=2.0&mrech={{MRECH}}&mrecy={{MRECY}}&pid={{PID}}&programa_id={{PROGAMA_ID}}&tipus=CCMA_NEXT&https=true&master=yes';

    const { isAuthenticated, accessToken, uuid, deviceType } = useUser();
    const { getApiData } = useData();

    const [options, setOptions] = useState(props);

    const [triggerManualRefetch, setTriggerManualRefetch] = useState(false);
    const [percentatgeVist, setPercentatgeVist] = useState(0);
    const [isVideoResumed, setIsVideoResumed] = useState();
    const [isVideoCompleted, setIsVideoCompleted] = useState(false);
    const [resumeText, setResumeText] = useState();
    const [urlDades, setUrlDades] = useState('');
    const [itemEstasVeient, setItemEstasVeient] = useState(null);

    const { loadData, id, durada, programaId } = options;
    const { data, refetch } = getApiData('ESTAS_VEIENT', urlDades);

    const getPercentatge = async () => {
        try {
            const tcVist = await getPercentatgeVist(id, durada);
            setPercentatgeVist(tcVist);
            const tcResumeText = await getResumeText(id, durada);
            setResumeText(tcResumeText);
            setIsVideoResumed(tcVist > 0 && tcVist < 99.5);
            setIsVideoCompleted(tcVist > 99.5);
        } catch (error) {
            console.log('Error fetching data getVistPlayList:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && accessToken && id && durada) {
            getPercentatge();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken, id, durada]);

    useEffect(() => {
        if (loadData) {
            const someUserData = { isAuthenticated, accessToken, uuid, deviceType, programaId };
            const url = options.url || url_dades;
            const updatedUrlDades = replaceClientDataUrlVariables(url, someUserData);

            if (isClientDataUrlReadyToUse(updatedUrlDades)) {
                setUrlDades(updatedUrlDades);
                setTriggerManualRefetch(true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated, accessToken, uuid, deviceType, options]);

    useEffect(() => {
        if (isAuthenticated && triggerManualRefetch) {
            refetch();
            setTriggerManualRefetch(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [triggerManualRefetch, isAuthenticated, refetch]);

    useEffect(() => {
        const apiItem = getItems(data);
        setItemEstasVeient(apiItem ? normalizeApiItems(apiItem)[0] : null);
    }, [data]);

    const refetchEstasVeientOptions = (_options) => {
        setOptions(_options);
    };

    return {
        percentatgeVist,
        isVideoResumed,
        isVideoCompleted,
        resumeText,
        itemEstasVeient,

        refetchEstasVeientOptions
    };
};
