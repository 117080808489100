import { cercaLogo, getCaducitat, getDurada, isPeli, trimBR } from '@portal-internet/core';
import clsx from 'clsx';
import CodiEspectador from 'components/3cat/CodiEspectador';
import Compartir from 'components/3cat/Compartir';
import Enllac from 'components/3cat/Enllac';
import Imatge from 'components/3cat/Imatge';
import Social from 'components/3cat/Social';
import AfegeixALaLlista from 'components/3cat/ui/AfegeixALaLlista';
import Boto from 'components/ui/Boto';
import Icona from 'components/3cat/ui/Icona';
import ProgressBar from 'components/3cat/ui/ProgressBar';
import InnerHTML from 'dangerously-set-html-content';
import { useEstasVeient } from 'hooks/3cat/useEstasVeient';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import styles from './destacat.module.scss';

const Share = (props) => {
    const { titol, tipologia, linkId, programaId, programaTipologia, route } = props;
    return (
        <>
            <AfegeixALaLlista
                className={styles.afegeix}
                tooltipPosition='topright'
                id={programaId || linkId}
                tipologia={programaTipologia || tipologia}
            />
            <Compartir titol={titol} className={styles.sharing} route={route} />
        </>
    );
};

const _getTextBoto = (props, isVideoResumed) => {
    const { textBoto } = props;
    let text = isVideoResumed ? 'CONTINUA' : textBoto;
    return !__isEmpty(text) ? text : 'MIRA-HO ARA';
};
const _getIconaBoto = (props) => {
    const { iconaBoto } = props;

    const text = _getTextBoto(props) === 'MIRA-HO ARA' ? 'play' : '';
    return !__isEmpty(iconaBoto) ? iconaBoto : text;
};
const _getTitol = (props) => {
    const { titol } = props;
    return titol;
};
const _getEntradeta = (props) => {
    const { entradetaPromo, entradeta } = props;
    return trimBR(!__isEmpty(entradetaPromo) ? entradetaPromo : entradeta);
};

const _getAudioDescripcio = (props) => {
    let result = false;

    if (!__isEmpty(props)) {
        result = props.filter((audiodescripcio) => audiodescripcio.id === 'AUD');
    }
    return result;
};

const _getSubtitols = (props) => {
    let result, subtitols;
    const text = [];

    // català
    result = props.filter((subtitol) => subtitol.id === 'CAT_CA');
    if (!__isEmpty(result)) {
        text.push('català');
    }

    // castellà
    result = props.filter((subtitol) => subtitol.id === 'CAT_ES');
    if (!__isEmpty(result)) {
        text.push('castellano (traducción automática)');
    }

    // anglès
    result = props.filter((subtitol) => subtitol.id === 'CAT_EN');
    if (!__isEmpty(result)) {
        text.push('English (automatic translation)');
    }

    if (text.length > 0) {
        subtitols = 'Subtítols en: ' + text.toString().replaceAll(',', ' / ');
        return subtitols;
    }

    return false;
};

const getContentDurada = (durada, isVideoResumed, resumeText) => {
    return (
        <>
            <div data-testid='destacat-durada'>
                <Icona className={styles.ico_clock} icona='rellotge' isBlanc={true} mida='13' alt='Icona rellotge' />
                <span>{isVideoResumed ? resumeText : getDurada(String(durada))}</span>
            </div>
        </>
    );
};

const Destacat = (props) => {
    const {
        supertitol,
        avantitol,
        titol,
        titolPrograma,
        entradeta,
        imatges,
        className,
        tipus,
        durada,
        codiEspectador,
        link,
        linkTipus,
        tipologia,
        tipologiaRelacionada,
        slugPrograma,
        linkId,
        canals,
        tema,
        urlFacebook,
        urlTwitter,
        urlInstagram,
        urlSpotify,
        urlYoutube,
        isWebviewActive,
        dataCaducitat,
        dataModificacio,
        dataPublicacio,
        tematica,
        variants,
        subtitols,
        logo,
        formats,
        sinopsi,
        tipusContingut
    } = props;

    const optionsEstasVeient = {
        loadData: true,
        durada,
        id: linkId
    };
    let { percentatgeVist, isVideoResumed, isVideoCompleted, resumeText } = useEstasVeient(optionsEstasVeient);

    const isPelicula = isPeli(formats);
    const textBoto = _getTextBoto(props, isVideoResumed);
    const iconaBoto = _getIconaBoto(props);

    const botoDesplegable = useRef();
    const textDesplegable = useRef();

    const [audioDesc, setAudioDesc] = useState(false);
    const [textAud, setTextAud] = useState('Activa');
    const changeAudioDesc = () => {
        setAudioDesc(!audioDesc);
        let textAud = audioDesc ? 'Activa' : 'Desactiva';
        setTextAud(textAud);
    };

    const stylesDestacat = clsx({
        [styles.destacatWebview]: true,
        [styles.bigDestacat]: !__isEmpty(supertitol),
        [styles.pellicula]: isPelicula,
        [styles[tipus]]: !__isEmpty(tipus),
        [styles[className]]: !__isEmpty(className)
    });

    const desplegaText = () => {
        const transitionDuration = (textDesplegable.current.scrollHeight - 72) / 240;
        textDesplegable.current.style.transitionDuration = transitionDuration + 's';
        textDesplegable.current.style.height = textDesplegable.current.scrollHeight + 'px';
        botoDesplegable.current.style.display = 'none';
        setTimeout(() => {
            textDesplegable.current.style.height = 'auto';
        }, transitionDuration * 1000);
    };

    useEffect(() => {
        function calculaMes() {
            if (!__isEmpty(textDesplegable.current)) {
                if (textDesplegable.current.scrollHeight > textDesplegable.current.clientHeight + 5) {
                    botoDesplegable.current.style.display = 'block';
                } else {
                    botoDesplegable.current.style.display = 'none';
                }
            }
        }
        calculaMes();
        window.addEventListener('resize', calculaMes);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const route = {
        href: link,
        slug: titol,
        slugPrograma: slugPrograma,
        id: linkId,
        type: linkTipus,
        tipologia: tipologia,
        tipologiaRelacionada: tipologiaRelacionada,
        canals: canals
    };

    const routePrograma = {
        slug: slugPrograma,
        tipologia: 'PTVC_PROGRAMA'
    };

    const hasLogo = cercaLogo(imatges, 'img_logo');

    const hasLogoPrograma = cercaLogo(logo, 'img_logo');

    const imatgeslogo = hasLogo ? imatges : logo;

    const titolDestacat = _getTitol(props);
    const entradetaDestacat = _getEntradeta(props);

    const mostrarCaducitat = dataCaducitat && getCaducitat(dataCaducitat);
    const mostrarDataActualitzacio =
        dataPublicacio &&
        !['PUFIC_HUMOR', 'PUFICCIO'].includes(tematica) &&
        (['PPW', 'PPD', 'CLIP_PPD'].includes(tipusContingut) || tipologia === 'WCR_AUDIO_MM');

    const _getProgressBar = () => {
        if (isVideoResumed || isVideoCompleted) {
            return (
                <div className={styles.dadesVideoContinuar}>
                    <div>
                        <ProgressBar className={styles.progress} progress={percentatgeVist} />
                    </div>
                </div>
            );
        }
        return <></>;
    };
    return (
        <>
            <div className={stylesDestacat}>
                {!__isEmpty(supertitol) && (
                    <h1 className={styles.supertitol} data-testid='destacat-supertitol'>
                        {supertitol}
                    </h1>
                )}

                <section className={styles.cos}>
                    <div className={styles.descripcio}>
                        {(hasLogo || hasLogoPrograma) && (
                            <div className={styles.logo} data-testid='destacat-logo'>
                                {!__isEmpty(slugPrograma) && (
                                    <Enllac route={routePrograma}>
                                        <Imatge
                                            imatges={imatgeslogo}
                                            tipus='web_logo'
                                            layout='fill'
                                            objectFit='contain'
                                            crop='310'
                                            mostrarRecurs={false}
                                        />
                                    </Enllac>
                                )}
                                {__isEmpty(slugPrograma) && (
                                    <Imatge
                                        imatges={imatgeslogo}
                                        tipus='web_logo'
                                        layout='fill'
                                        objectFit='contain'
                                        crop='310'
                                        mostrarRecurs={false}
                                    />
                                )}
                            </div>
                        )}
                        {!hasLogo && !hasLogoPrograma && !isPelicula && (
                            <div className={styles.titolSenseLogo} data-testid='destacat-titol-sense-logo'>
                                {titolPrograma}
                            </div>
                        )}

                        {isPelicula && (
                            <span className={styles.movieLabel} data-testid='destacat-tag-peli'>
                                Pel·lícula
                            </span>
                        )}
                        <Enllac route={route}>
                            {!__isEmpty(avantitol) && <span className={styles.avantitol}>{avantitol}</span>}
                            <h2 data-testid='destacat-titol'>{titolDestacat}</h2>
                        </Enllac>

                        {!__isEmpty(entradetaDestacat) && isPelicula && (
                            <div className={styles.entradetaPeli} data-testid='destacat-entradeta-peli'>
                                <span dangerouslySetInnerHTML={{ __html: entradetaDestacat }}></span>
                            </div>
                        )}

                        <div className={styles.dadesVideo}>
                            {!__isEmpty(codiEspectador) && (
                                <CodiEspectador
                                    codi={codiEspectador[0].id}
                                    alt={codiEspectador[0].desc}
                                    mida='20'
                                    className={styles.ico_codi}
                                />
                            )}

                            {!__isEmpty(durada) && getContentDurada(durada, isVideoResumed, resumeText)}

                            {mostrarDataActualitzacio && (
                                <div className={styles.pubdate} data-testid='destacat-data-actualitzacio'>
                                    {dataPublicacio.split(' ').shift()}
                                </div>
                            )}
                            {mostrarCaducitat && (
                                <div className={styles.pubdate} data-testid='destacat-caducitat'>
                                    {getCaducitat(dataCaducitat)}
                                </div>
                            )}
                        </div>
                        {_getProgressBar()}

                        <div className={styles.botons}>
                            <Boto
                                data_testid='destacat-boto'
                                text={textBoto}
                                icona={iconaBoto}
                                className={styles.botoPlay}
                                iconaInline={true}
                                isIconaDreta={true}
                                tag='a'
                                canals={canals}
                                tipologia={tipologia}
                                tipologiaRelacionada={tipologiaRelacionada}
                                slugPrograma={slugPrograma}
                                linkId={linkId}
                                linkSlug={titol}
                                type={linkTipus}
                                format={isWebviewActive ? 'PLAYER' : ''}
                                link={link}
                                tema={tema}
                                fons='fosc'
                            />
                            <div className={styles.logoSharing}>
                                <Share {...props} route={route} />
                            </div>
                        </div>
                        {!__isEmpty(entradetaDestacat) && !isPelicula && (
                            <div className={styles.wrapperEntradeta} data-testid='destacat-entradeta'>
                                <div className={styles.entradeta} ref={textDesplegable}>
                                    <span dangerouslySetInnerHTML={{ __html: entradetaDestacat }}></span>
                                </div>
                                <button className={styles.mes} ref={botoDesplegable}>
                                    … <span onClick={() => desplegaText()}>Més</span>
                                </button>
                            </div>
                        )}

                        {(!__isEmpty(subtitols) || _getAudioDescripcio(variants)) && (
                            <>
                                <div className={styles.subtitols} data-testid='destacat-subtitols'>
                                    {_getAudioDescripcio(variants) && (
                                        <CodiEspectador
                                            codi='AD'
                                            alt='icona audiodescripció'
                                            mida='20'
                                            className={styles.ico_ad}
                                        />
                                    )}
                                    {!__isEmpty(subtitols) && <CodiEspectador codi='Subtitols' alt='icona subtítols' mida='20' />}
                                    <p className={styles.txt_subtitols}>{_getSubtitols(subtitols)}</p>
                                </div>
                            </>
                        )}
                    </div>

                    <Social
                        facebook={urlFacebook}
                        twitter={urlTwitter}
                        instagram={urlInstagram}
                        youtube={urlYoutube}
                        spotify={urlSpotify}
                        className={styles.social}
                        isWebviewActive={isWebviewActive}
                    />
                    <div className={styles.imatgeWrapper}>
                        <Imatge
                            imatges={imatges}
                            className={styles.imatge}
                            tipus='web_destacat'
                            alt={titol}
                            aria-hidden='true'
                            layout='fill'
                            loading='lazy'
                            objectFit='cover'
                            priority={true}
                            crop='1903'
                        />
                    </div>
                </section>
            </div>
            {isPelicula && (entradeta || sinopsi) && (
                <div className={styles.sinopsi + ' ' + styles.sinopsi_webview}>
                    {entradeta && (
                        <div className={styles.sinopsi_entradeta}>
                            <p>
                                <InnerHTML html={entradeta} />
                            </p>
                        </div>
                    )}
                    {sinopsi && <InnerHTML className={styles.sinopsi_text + ' ' + styles.peli} html={sinopsi} />}
                </div>
            )}
        </>
    );
};

Destacat.defaultProps = {
    titol: '',
    entradeta: '',
    tipus: '',
    isWebviewActive: false
};

export default Destacat;
